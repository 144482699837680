import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class SnackBarService {

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {
  
  }

  success(message: string, horizontalPosition: MatSnackBarHorizontalPosition = 'center', verticalPosition: MatSnackBarVerticalPosition = 'top', duration: number = 3000) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['success-snackbar'];
    config.horizontalPosition = horizontalPosition;
    config.verticalPosition = verticalPosition;
    config.duration = duration;
    this.snackBar.open(message,undefined ,config);
  }

  error(message: string, horizontalPosition: MatSnackBarHorizontalPosition = 'center', verticalPosition: MatSnackBarVerticalPosition = 'top', duration: number = 6000) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['error-snackbar'];
    config.horizontalPosition = horizontalPosition;
    config.verticalPosition = verticalPosition;
    config.duration = duration;
    this.snackBar.open(message, this.translate.instant('OKAY'), config);
  }

  info(message: string, horizontalPosition: MatSnackBarHorizontalPosition = 'center', verticalPosition: MatSnackBarVerticalPosition = 'top', duration: number = 3000) {
    const config = new MatSnackBarConfig();
    config.panelClass = ['info-snackbar'];
    config.horizontalPosition = horizontalPosition;
    config.verticalPosition = verticalPosition;
    config.duration = duration;
    this.snackBar.open(message, this.translate.instant('CONFIRM'), config);
  }
}
